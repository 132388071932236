import { useCallback, useEffect, useState } from 'react';
import {
  FocusContext,
  setFocus,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import Loader from 'components/Loader/Loader';
import { ContentRowTitle } from 'components/ContentRow/styled';
import Asset from 'components/Asset/Asset';
import { Movie, searchMovies } from 'api/ua-kino';
import { ContentWrapper, ScrollingRows } from 'styled';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from './styled';

const SearchPage = () => {
  const { ref, focusKey } = useFocusable();
  const [movies, setMovies] = useState<Movie[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const { ref: searchRef, focused: searchFocused } = useFocusable({
    focusKey: 'search',
    // onFocus,
    onEnterPress: () => {
      searchRef.current.focus();
    },
    onBlur: () => {
      searchRef.current.blur();
    },
  });

  const fetchData = async () => {
    setIsLoading(true);
    const data = await searchMovies(search);
    setMovies(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [search]);

  useEffect(() => {
    if (movies.length) {
      const index = Math.ceil(movies.length / 40 - 1) * 40;
      movies[index]?.link && setFocus(movies[index].link);
    }
  }, [movies]);

  const onAssetFocus = useCallback(
    ({ y }: { y: number }) => {
      ref.current.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    },
    [ref],
  );

  const openMovie = (link: string) => {
    navigate(`/movie/?link=${link}`);
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <Loader isLoading={isLoading}>
        <ContentWrapper>
          <ContentRowTitle>Пошук</ContentRowTitle>
          <SearchInput
            ref={searchRef}
            focused={searchFocused}
            placeholder='Почніть писати...'
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
          <ScrollingRows ref={ref}>
            {movies.map(movie => (
              <Asset
                key={movie.link}
                focusKey={movie.link}
                title={movie.name}
                image={movie.image}
                onFocus={onAssetFocus}
                onEnterPress={() => openMovie(movie.link)}
              />
            ))}
          </ScrollingRows>
        </ContentWrapper>
      </Loader>
    </FocusContext.Provider>
  );
};

export default SearchPage;
