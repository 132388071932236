import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loader from 'components/Loader/Loader';
import {
  getSources,
  MovieInfo,
  SourcesData,
  WatchedEpisode,
} from 'api/ua-kino';
import Button from 'components/Button/Button';
import { Container, ScrollWrapper, Title } from './styled';
import { useNavigate } from 'react-router-dom';
import { WATCH_HISTORY } from 'constants/settings';

export interface SourcesSectionProps {
  movie: MovieInfo;
  link: string;
}

const getWatchedPercentage = (link: string, history: WatchedEpisode[]) => {
  const episode = history.find(item => item.link === link);
  return episode?.viewedPercentage
    ? ` - ${Math.floor(episode.viewedPercentage)}%`
    : '';
};

const SourcesSection = ({
  movie: { id, title, subTitle, url: movieUrl, seasons },
  link,
}: SourcesSectionProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sourcesData, setSourcesData] = useState<SourcesData | null>(null);
  const [selectedSource, setSelectedSource] = useState(0);
  const navigate = useNavigate();
  const watchedHistory: WatchedEpisode[] =
    JSON.parse(localStorage.getItem(WATCH_HISTORY) || '[]') ?? [];

  const fetchData = async () => {
    if (id) {
      setIsLoading(false);
      const data = await getSources(id);
      setSourcesData(data);
      setSelectedSource(0);
      setIsLoading(false);
    } else if (movieUrl) {
      setSourcesData({
        sources: [],
        videos: [
          {
            id: 'main',
            name: 'Українська',
            link: movieUrl,
            voice: 'Не відомо',
          },
        ],
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const onFocus = useCallback(
    ({ y }: { y: number }) => {
      ref.current?.scrollTo({
        top: y - 50,
        behavior: 'smooth',
      });
    },
    [ref],
  );

  const getVideos = () => {
    if (!sourcesData) return [];

    if (sourcesData.sources.length) {
      return sourcesData.videos.filter(
        video => video.id === sourcesData.sources[selectedSource].id,
      );
    }
    return sourcesData.videos;
  };

  const onVideoPress = (link?: string) => {
    if (link) {
      navigate(`/player/?link=${link}&title=${title}&subTitle=${subTitle}`);
    }
  };

  const openMovie = (link: string) => {
    navigate(`/movie/?link=${link}`, { replace: true });
  };

  return (
    <ScrollWrapper ref={ref}>
      <Loader isLoading={isLoading}>
        <Container>
          {!id && !sourcesData?.videos.length && <div>Джерела не занйдено</div>}
          {!!seasons.length && <Title>Сезони</Title>}
          {seasons.map((season, index) => (
            <Button
              key={season.link}
              onEnterPress={() => openMovie(season.link!)}
              onFocus={onFocus}
              focusOnMount
              selected={link === season.link}
            >
              {season.name}
            </Button>
          ))}
          <Title>Джерела</Title>
          {sourcesData &&
            sourcesData.sources.map((source, index) => (
              <Button
                key={source.id}
                onEnterPress={() => setSelectedSource(index)}
                onFocus={onFocus}
                focusOnMount
                selected={selectedSource === index}
              >
                {source.name}
              </Button>
            ))}
          {sourcesData && !!sourcesData.sources.length && <Title>Серії</Title>}
          {getVideos().map(video => (
            <Button
              key={video.link}
              onEnterPress={() => onVideoPress(video.link)}
              onFocus={onFocus}
            >
              {video.name}
              {video.link && getWatchedPercentage(video.link, watchedHistory)}
            </Button>
          ))}
        </Container>
      </Loader>
    </ScrollWrapper>
  );
};

export default SourcesSection;
