import styled from 'styled-components';
import { pxToRem } from 'utils';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: black;
`;

export const Controls = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${pxToRem(200)};
  box-sizing: border-box;
  background: linear-gradient(180deg, #00000000, #0a0911);
`;

export const PlayButtons = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  display: flex;
  gap: 1.25rem;
`;

export const Duration = styled.div`
  position: absolute;
  top: 50%;
  left: ${pxToRem(70)};
  transform: translateY(-50%);
  font-family: 'Chivo Mono', monospace;
  font-size: 2.5rem;
  opacity: 0.9;
`;

export const Title = styled.div`
  position: absolute;
  top: 0;
  left: ${pxToRem(70)};
  font-size: 3.75rem;
  font-weight: 700;

  div:last-child {
    font-size: 2rem;
    opacity: 0.8;
    margin-top: 0.5rem;
  }
`;

export const SettingsBox = styled.div`
  position: absolute;
  top: 50%;
  right: ${pxToRem(70)};
  transform: translateY(-50%);
`;

export const QualityBox = styled.div`
  position: absolute;
  transform: translateY(-100%);
  top: 0;
  right: ${pxToRem(1)};
  display: flex;
  gap: 1.25rem;
  flex-direction: column;
  width: ${pxToRem(172)};
  background: linear-gradient(135deg, #6c36b1, #0a0911);
  padding: 0.5rem;
  border-radius: 3rem;
  border: ${pxToRem(1)} solid rgba(255, 255, 255, 0.1);
`;
