import React, { useCallback, useEffect, useState } from 'react';
import {
  FocusContext,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { ContentRowTitle } from 'components/ContentRow/styled';
import Asset from 'components/Asset/Asset';
import { Movie, saveToHistory } from 'api/ua-kino';
import { ContentWrapper, ScrollingRows } from 'styled';
import { useNavigate } from 'react-router-dom';
import { HISTORY } from 'constants/settings';

interface HistoryPageProps {}

const HistoryPage = ({}: HistoryPageProps) => {
  const { ref, focusKey } = useFocusable();
  const [movies, setMovies] = useState<Movie[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const movies = JSON.parse(localStorage.getItem(HISTORY) || '[]');
    setMovies(movies.sort((a: any, b: any) => b.time - a.time));
  }, []);

  const onAssetFocus = useCallback(
    ({ y }: { y: number }) => {
      ref.current.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    },
    [ref],
  );

  const openMovie = (link: string) => {
    navigate(`/movie/?link=${link}`);
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <ContentWrapper>
        <ContentRowTitle>Історія перегляду</ContentRowTitle>
        <ScrollingRows ref={ref}>
          {movies.length === 0 && <div>Історія пуста</div>}
          {movies.map(movie => (
            <Asset
              key={movie.link}
              focusKey={movie.link}
              title={movie.name}
              image={movie.image}
              onFocus={onAssetFocus}
              onEnterPress={() => openMovie(movie.link)}
            />
          ))}
        </ScrollingRows>
      </ContentWrapper>
    </FocusContext.Provider>
  );
};

export default HistoryPage;
