import React from 'react';
import {
  FocusableComponentLayout,
  FocusDetails,
  KeyPressDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import {
  AssetImage,
  AssetTitle,
  AssetWrapper,
  SelectedAssetTitle,
} from './styled';

export interface AssetProps {
  image?: string;
  title: string;
  focusKey: string;
  keyPrefix?: string;
  onFocus: (
    layout: FocusableComponentLayout,
    props: object,
    details: FocusDetails,
  ) => void;

  width?: number;
  height?: number;
  onEnterPress?: (details: KeyPressDetails) => void;
  isSelected?: boolean;
}

const Asset = ({
  image,
  title,
  focusKey,
  onFocus,
  keyPrefix,
  width,
  height,
  onEnterPress,
  isSelected,
}: AssetProps) => {
  const { ref, focused } = useFocusable({
    focusKey: focusKey + (keyPrefix || ''),
    onFocus,
    onEnterPress,
  });

  return (
    <AssetWrapper ref={ref} width={width}>
      {image && (
        <AssetImage
          src={image}
          loading='lazy'
          focused={focused}
          width={width}
          height={height}
          referrerPolicy='no-referrer'
        />
      )}
      {isSelected && (
        <SelectedAssetTitle height={height}>
          <img src='/images/icons/selected.svg' alt='selected' />
          Вибрано
        </SelectedAssetTitle>
      )}
      <AssetTitle>{title}</AssetTitle>
    </AssetWrapper>
  );
};

export default Asset;
