import styled from 'styled-components';

export interface ButtonBoxProps {
  focused: boolean;
}

export const ButtonBox = styled.div<ButtonBoxProps>`
  padding: 1.5rem 4rem;
  font-size: 2rem;
  object-fit: cover;
  border-color: 'white';
  background: ${({ focused }) => (focused ? '#ffffff33' : 'transparent')};
  border-style: solid;
  border-width: 0.25rem;
  box-sizing: border-box;
  border-radius: 4rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 3.75rem;
  }
`;
