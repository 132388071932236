import styled, { createGlobalStyle } from 'styled-components';
import { pxToRem } from 'utils';

export const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    display: none;
  }
`;

export interface AppContainerProps {
  backgroundIndex: number;
}

export const AppContainer = styled.div<AppContainerProps>`
  position: relative;
  background-color: #221c35;
  width: ${pxToRem(1280)};
  height: ${pxToRem(720)};
  display: flex;
  flex-direction: row;
  background-size: cover;
  background-image: linear-gradient(225deg, #25223380, #0a0911),
    ${({ backgroundIndex }) => `url('/images/${backgroundIndex}.jpg')`};
`;

export const ContentBox = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 4.5rem;
  color: white;
`;

export const ScrollingRows = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  padding-left: 7.5rem;
`;
