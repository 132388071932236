import styled from 'styled-components';
import { pxToRem } from 'utils';

interface MenuWrapperProps {
  hasFocusedChild: boolean;
}

export const MenuWrapper = styled.div<MenuWrapperProps>`
  flex: 1;
  max-width: ${pxToRem(252)};
  min-width: ${pxToRem(252)};
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: ${({ hasFocusedChild }) =>
    hasFocusedChild ? '#4e4181' : '#362C56'}; */
  padding-top: 4.5rem;
`;
