import React, { useCallback, useEffect, useState } from 'react';
import { AppContainer, ContentBox, GlobalStyle } from './styled';
import Menu from './components/Menu/Menu';
import HomePage from './pages/HomePage/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getMainPageData, MovieType, Section } from 'api/ua-kino';
import MoviesPage from 'pages/MoviesPage/MoviesPage';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import { BACKGROUND_KEY } from 'constants/settings';
import MoviePage from 'pages/MoviePage/MoviePage';
import PlayerPage from 'pages/PlayerPage/PlayerPage';
import HistoryPage from 'pages/HistoryPage/HistoryPage';
import SearchPage from 'pages/SearchPage/SearchPage';

function App() {
  const [backgroundIndex, setBackgroundIndex] = useState(1);
  const [sections, setSections] = useState<Section[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    const data = await getMainPageData();
    setSections(data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const storedBackgroundIndex = localStorage.getItem(BACKGROUND_KEY);
    if (!storedBackgroundIndex) {
      localStorage.setItem(BACKGROUND_KEY, '1');
    } else {
      setBackgroundIndex(Number(storedBackgroundIndex));
    }

    fetchData();
    console.log('App mounted');
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        window.history.back();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <BrowserRouter>
      <AppContainer backgroundIndex={backgroundIndex}>
        <GlobalStyle />
        <Routes>
          <Route path='/movie/' element={<MoviePage />} />
          <Route path='/player/' element={<PlayerPage />} />
          <Route
            path='*'
            element={
              <>
                <Menu focusKey='MENU' />
                <ContentBox>
                  <Routes>
                    <Route
                      path='/'
                      element={
                        <HomePage isLoading={isLoading} sections={sections} />
                      }
                    />
                    <Route path='/history' element={<HistoryPage />} />
                    <Route
                      path='/movies'
                      element={
                        <MoviesPage type={MovieType.Movies} title='Фільми' />
                      }
                    />
                    <Route
                      path='/series'
                      element={
                        <MoviesPage type={MovieType.Series} title='Серіали' />
                      }
                    />
                    <Route
                      path='/cartoons'
                      element={
                        <MoviesPage
                          type={MovieType.Cartoons}
                          title='Мультфільми'
                        />
                      }
                    />
                    <Route
                      path='/settings'
                      element={
                        <SettingsPage
                          setBackgroundIndex={setBackgroundIndex}
                          backgroundIndex={backgroundIndex}
                        />
                      }
                    />
                    <Route path='/search/' element={<SearchPage />} />
                    <Route path='*' element={<div>Сторінку не знайдено</div>} />
                  </Routes>
                </ContentBox>
              </>
            }
          />
        </Routes>
      </AppContainer>
    </BrowserRouter>
  );
}

export default App;
