import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

export const LoaderElement = styled.div`
  display: inline-block;
  position: relative;
  width: 10rem;
  height: 10rem;
  position: relative;

  div {
    display: inline-block;
    position: absolute;
    left: 1rem;
    width: 2rem;
    background: #ffffff80;
    animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  div:nth-child(1) {
    left: 1rem;
    animation-delay: -0.24s;
  }
  div:nth-child(2) {
    left: 4rem;
    animation-delay: -0.12s;
  }
  div:nth-child(3) {
    left: 7rem;
    animation-delay: 0;
  }
  @keyframes loading {
    0% {
      top: 1rem;
      height: 8rem;
    }
    50%,
    100% {
      top: 2rem;
      height: 4rem;
    }
  }
`;
