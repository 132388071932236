import styled from 'styled-components';
import { pxToRem } from 'utils';

export const Wrapper = styled.div`
  width: 100%;
  padding: 4.5rem;
`;

export const Title = styled.div`
  font-size: 4.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  img {
    width: 6.25rem;
    height: 6.25rem;
    margin-right: 1.25rem;
  }
`;

export const SybTitle = styled.div`
  font-size: 2rem;
  margin-left: 7.5rem;
  opacity: 0.6;
`;

export const Content = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 4.5rem;
  height: ${pxToRem(460)};
  display: flex;
`;

export const Actions = styled.div`
  height: 5.75rem;
  display: flex;

  div {
    margin-right: 2rem;
  }
`;

export const Poster = styled.img`
  min-width: ${pxToRem(320)};
  width: ${pxToRem(320)};
  height: ${pxToRem(460)};
  object-fit: cover;
  box-shadow: 0px 0px 2rem -0.5rem #00000080;
`;

export const Info = styled.div`
  font-size: 2.25rem;
  margin-left: 4.5rem;
  line-height: 3.25rem;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const InfoRow = styled.div`
  display: flex;
  margin-bottom: 1rem;

  div:first-child {
    margin-right: 1rem;
    opacity: 0.8;
  }
`;

export const Description = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
`;
