import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import {
  getVideo,
  saveWatchedEpisodeLink,
  VideoConfig,
  WatchedEpisode,
} from 'api/ua-kino';
import Loader from 'components/Loader/Loader';
import {
  Controls,
  Duration,
  PlayButtons,
  QualityBox,
  SettingsBox,
  Title,
  Wrapper,
} from './styled';
import IconButton from 'components/IconButton/IconButton';
import { AUTO_QUALITY_MODE, WATCH_HISTORY } from 'constants/settings';
import Button from 'components/Button/Button';
import {
  FocusContext,
  setFocus,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';

interface PlayerPageProps {}

interface QualityLevel {
  id: number;
  label: string;
}

const PlayerPage = ({}: PlayerPageProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const link = searchParams.get('link');
  const title = searchParams.get('title');
  const subTitle = searchParams.get('subTitle');

  const [videoConfig, setVideoConfig] = useState<VideoConfig | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const playerRef = useRef<ReactPlayer>(null);
  const durationRef = useRef<HTMLDivElement>(null);
  const { focusKey } = useFocusable();

  const [isQualityBoxVisible, setIsQualityBoxVisible] = useState(false);
  const [qualities, setQualities] = useState<QualityLevel[]>([]);

  const [isControlsVisible, setIsControlsVisible] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentQuality, setCurrentQuality] = useState(-1);

  if (!link) {
    navigate('/');
    return null;
  }

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const showControls = () => {
    timeout.current && clearTimeout(timeout.current);
    setIsControlsVisible(true);
    timeout.current = setTimeout(() => {
      setIsControlsVisible(false);
      setIsQualityBoxVisible(false);
      setFocus('playButton');
    }, 3000);
  };

  const onKeyPress = (event: KeyboardEvent) => {
    showControls();
    if (event.key === 'Backspace') {
      navigate(-1);
    }
  };

  const fetchVideo = async (link: string) => {
    setIsLoading(true);
    const videoConfig = await getVideo(link);
    setVideoConfig(videoConfig);
  };

  useEffect(() => {
    if (link) {
      fetchVideo(link);
    }
  }, [link]);

  useEffect(() => {
    showControls();
    document.addEventListener('keydown', onKeyPress);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
    };
  }, []);

  const onReady = (player: ReactPlayer) => {
    const hlsPlayer = player.getInternalPlayer('hls');
    const levels = [
      { id: -1, label: 'Авто' },
      ...hlsPlayer.levels.map((level: any, index: number) => ({
        id: index,
        label: level.attrs.RESOLUTION,
      })),
    ];
    setQualities(levels);
    if (localStorage.getItem(AUTO_QUALITY_MODE) === 'best') {
      changeQuality(levels[levels.length - 1].id);
    }
  };

  const onDuration = (duration: number) => {
    const watchedHistory: WatchedEpisode[] =
      JSON.parse(localStorage.getItem(WATCH_HISTORY) || '[]') ?? [];
    const watchedEpisode = watchedHistory.find(item => item.link === link);
    const player = playerRef.current;

    if (watchedEpisode && player) {
      console.log(
        'seeking to',
        player.getDuration(),
        +watchedEpisode.viewedPercentage,
      );
      player.seekTo(
        player.getDuration() * (+watchedEpisode.viewedPercentage / 100),
        'seconds',
      );
    }
  };

  const changeQuality = (index: number) => {
    const hlsPlayer = playerRef.current?.getInternalPlayer('hls');
    if (hlsPlayer) {
      hlsPlayer.currentLevel = index;
      setCurrentQuality(index);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const onSeek = (diffSeconds: number) => {
    const player = playerRef.current;
    if (player) {
      const currentTime = player.getCurrentTime();
      player.seekTo(currentTime + diffSeconds, 'seconds');
    }
  };

  const formatTime = useCallback((timeInSeconds?: number) => {
    if (!timeInSeconds) return '00:00:00';
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);

    const format = (value: number) => (value < 10 ? `0${value}` : value);
    return `${format(hours)}:${format(minutes)}:${format(seconds)}`;
  }, []);

  const calculateDuration = () => {
    return (
      formatTime(playerRef.current?.getCurrentTime()) +
      ' / ' +
      formatTime(playerRef.current?.getDuration())
    );
  };

  const onProgress = () => {
    if (durationRef.current) {
      durationRef.current.innerText = calculateDuration();

      const viewedPercentage =
        ((playerRef.current?.getCurrentTime() || 0) /
          (playerRef.current?.getDuration() || 1)) *
        100;
      saveWatchedEpisodeLink({ link, viewedPercentage });
    }
  };

  const toggleSettings = () => {
    setIsQualityBoxVisible(prev => !prev);
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <Loader isLoading={isLoading}>
        {videoConfig?.url && (
          <Wrapper>
            <ReactPlayer
              onReady={onReady}
              ref={playerRef}
              width='100%'
              height='100%'
              url={videoConfig.url}
              playing={isPlaying}
              onProgress={onProgress}
              onDuration={onDuration}
            />
            <Controls style={{ display: isControlsVisible ? 'block' : 'none' }}>
              <Title>
                <div>{title}</div>
                <div>{subTitle}</div>
              </Title>
              <Duration ref={durationRef}>{calculateDuration()}</Duration>
              <PlayButtons>
                <IconButton icon='backward' onEnterPress={() => onSeek(-15)} />
                <IconButton
                  icon={isPlaying ? 'pause' : 'play'}
                  onEnterPress={togglePlayPause}
                  focusOnMount
                  circle
                  focusKey='playButton'
                />
                <IconButton icon='forward' onEnterPress={() => onSeek(15)} />
              </PlayButtons>
              <SettingsBox>
                <IconButton icon='setting2' onEnterPress={toggleSettings} />

                {isQualityBoxVisible && (
                  <QualityBox>
                    {qualities.map(quality => (
                      <Button
                        key={quality.id}
                        onEnterPress={() => changeQuality(quality.id)}
                        selected={currentQuality === quality.id}
                      >
                        {quality.label}
                      </Button>
                    ))}
                  </QualityBox>
                )}
              </SettingsBox>
            </Controls>
          </Wrapper>
        )}
      </Loader>
    </FocusContext.Provider>
  );
};

export default PlayerPage;
