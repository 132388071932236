import styled from 'styled-components';
import { pxToRem } from 'utils';

export interface AssetWrapperProps {
  width?: number;
}

export const AssetWrapper = styled.div<AssetWrapperProps>`
  margin-right: 2.75rem;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? pxToRem(width) : pxToRem(132.5))};
  margin-bottom: 2rem;
  position: relative;
`;

export interface AssetBoxProps {
  focused: boolean;
  width?: number;
  height?: number;
}

export const AssetImage = styled.img<AssetBoxProps>`
  background: linear-gradient(225deg, #25223380, #0a0911);
  width: ${({ width }) => (width ? pxToRem(width) : pxToRem(132.5))};
  height: ${({ height }) => (height ? pxToRem(height) : pxToRem(200))};
  object-fit: cover;
  border-color: white;
  border-style: solid;
  border-width: ${({ focused }) => (focused ? '0.5rem' : 0)};
  box-sizing: border-box;
  border-radius: 1rem;
  box-shadow: 0px 0px 15px -5px #00000080;
`;

export const AssetTitle = styled.div`
  color: white;
  margin-top: 1.25rem;
  font-size: 1.75rem;
  font-weight: 600;
`;

interface SelectedAssetTitleProps {
  height?: number;
}

export const SelectedAssetTitle = styled.div<SelectedAssetTitleProps>`
  color: white;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: calc(
    ${({ height }) => (height ? pxToRem(height) : pxToRem(200))} - 3rem
  );
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.6);

  img {
    width: 2rem;
    margin-right: 0.5rem;
  }
`;
