import React, { useEffect } from 'react';
import {
  FocusableComponentLayout,
  FocusDetails,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { IconButtonBox } from './styled';

export interface IconButtonProps {
  onEnterPress: () => void;
  focusOnMount?: boolean;
  onFocus?: (
    layout: FocusableComponentLayout,
    props: object,
    details: FocusDetails,
  ) => void;
  icon: string;
  circle?: boolean;
  focusKey?: string;
}

const IconButton = ({
  onEnterPress,
  focusOnMount,
  onFocus,
  icon,
  circle,
  focusKey,
}: IconButtonProps) => {
  const { ref, focused, focusSelf } = useFocusable({
    focusKey,
    onFocus,
    onEnterPress,
  });

  useEffect(() => {
    if (focusOnMount) {
      focusSelf();
    }
  }, []);

  return (
    <IconButtonBox ref={ref} focused={focused} circle={circle}>
      <svg viewBox='0 0 4rem 4rem' width='4rem' height='4rem'>
        <use href={`/images/icons/${icon}.svg#${icon}`}></use>
      </svg>
    </IconButtonBox>
  );
};

export default IconButton;
