import styled from 'styled-components';
import { pxToRem } from 'utils';

export interface IconButtonBoxProps {
  focused: boolean;
  circle?: boolean;
}

export const IconButtonBox = styled.div<IconButtonBoxProps>`
  width: 4rem;
  height: 4rem;
  padding: 2rem;

  color: #fff;
  ${({ circle, focused }) =>
    circle &&
    `
    background-color:  ${focused ? '#6c36b1' : 'rgba(255,255,255,0.2)'};
    border: ${pxToRem(1)} solid rgba(255,255,255,0.1);
    border-radius: 8rem;
    `}

  svg {
    fill: ${({ focused, circle }) =>
      focused && !circle ? '#6c36b1' : 'white'};
    width: 4rem;
    height: 4rem;
  }
`;
