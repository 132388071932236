import styled from 'styled-components';
import { pxToRem } from 'utils';

export const SubTitle = styled.div`
  min-width: 100%;
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  font-size: 2.5rem;
`;

export const DebagOutput = styled.div`
  min-width: 100%;
  white-space: pre;
  font: 1.75rem/1.5 monospace;
`;

export const QualityBox = styled.div`
  width: ${pxToRem(600)};
  display: flex;
  gap: 1.25rem;
  margin-top: 0.5rem;
`;
