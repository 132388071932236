import styled from 'styled-components';

export const ContentRowWrapper = styled.div`
  margin-bottom: 1.75rem;
`;

export const ContentRowTitle = styled.div`
  color: white;
  margin-bottom: 1.5rem;
  font-size: 3.25rem;
  font-weight: 700;
  padding-left: 7.5rem;
`;

export const ContentRowScrollingWrapper = styled.div`
  overflow-x: visible;
  overflow-y: hidden;
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: 7.5rem;
  padding-right: 7.5rem;
  mask-image: linear-gradient(270deg, black 93%, transparent);
  -webkit-mask-image: linear-gradient(270deg, black 93%, transparent);
`;

export const ContentRowScrollingContent = styled.div`
  display: flex;
  flex-direction: row;

  &::after {
    content: ',';
    visibility: hidden;
    display: block;
    width: 4.5rem;
    height: 1.25rem;
    margin-left: 0;
  }
`;
