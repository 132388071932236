import styled from 'styled-components';

export const SearchInput = styled.input<{ focused: boolean }>`
  margin: 3rem 7.5rem;
  height: 7.5rem;
  font-size: 4.5rem;
  background-color: transparent;
  border: ${({ focused }) => (focused ? '0.5rem' : '0.25rem')} solid white;
  border-radius: 3.75rem;
  padding: 0 3.75rem;
  color: white;
  outline: none;
`;
