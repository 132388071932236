import styled from 'styled-components';
import { pxToRem } from 'utils';

interface MenuItemBoxProps {
  focused: boolean;
}

export const MenuItemBox = styled.div<MenuItemBoxProps>`
  width: ${pxToRem(171)};
  color: ${({ focused }) => (focused ? '#fff' : '#9e9e9e')};
  font-weight: ${({ focused }) => (focused ? 700 : 400)};
  font-size: 3rem;
  margin-bottom: 4.5rem;
  display: flex;
  align-items: center;
  line-height: 1;

  img {
    margin-right: 1.5rem;
    width: 3.75rem;
  }
  div {
    margin-top: 0.5rem;
  }
`;
