import { Cheerio, CheerioAPI, load } from 'cheerio';
import { HISTORY, WATCH_HISTORY } from 'constants/settings';
import { Element } from 'domhandler';

export const PROXY_URL = 'https://proxy.mplayer.uk/?';
export const URL = 'https://uakino.me';

export interface Movie {
  name: string;
  link: string;
  image: string;
  genre?: string;
  year?: string;
  actors?: string;
  imdb?: string;
}

export interface MovieInfo {
  title: string;
  subTitle: string;
  image: string | undefined;
  quality: string;
  description: string;
  infoSections: {
    title: string;
    value: string;
  }[];
  id: string | undefined;
  url: string | undefined;
  seasons: {
    name: string;
    link: string | undefined;
  }[];
}

export interface SourcesData {
  sources: {
    id: string | undefined;
    name: string;
  }[];
  videos: {
    name: string;
    id: string | undefined;
    link: string | undefined;
    voice: string | undefined;
  }[];
}

export interface VideoConfig {
  url?: string;
  poster?: string;
  subtitle?: string;
}

export interface Section {
  name: string;
  movies: Movie[];
}

export enum MovieType {
  Movies = 'filmy',
  Series = 'seriesss',
  Cartoons = 'cartoon',
}
const get = (
  url?: string,
  isFullLink = false,
  isJSON = false,
  withProxy = true,
  headers?: HeadersInit,
) =>
  fetch((withProxy ? PROXY_URL : '') + (isFullLink ? '' : URL) + url, {
    headers,
  }).then(res => (isJSON ? res.json() : res.text()));

const findMovieItems = ($parent: Cheerio<Element>, $: CheerioAPI) =>
  $parent
    .find('.movie-item')
    .map((_, movie) => {
      const movieElement = $(movie);
      return {
        name: movieElement.find('.movie-title').text(),
        link: movieElement.find('.movie-title').attr('href') || '',
        image: URL + movieElement.find('img').attr('src'),
        genre: movieElement.find('.movie-genre1').text().trim(),
        year: movieElement.find('.movie-desc div:nth-child(5)').text().trim(),
        actors: movieElement.find('.deck-cast1').text().trim(),
        imdb: movieElement.find('.movie-desc div:nth-child(9)').text().trim(),
      };
    })
    .toArray();

export const getMainPageData = async () => {
  const data = await get('/');
  const $ = load(data as any);

  const sections = $('.main-section-inner')
    .map((_, section) => {
      const $section = $(section);
      const sectionName = $section
        .find('.sidebar-title')
        .text()
        .replace(/\n/g, ' ')
        .trim();

      return {
        name: sectionName,
        movies: findMovieItems($section, $),
      };
    })
    .toArray();

  return sections.filter(section => section.movies.length);
};

export const getMovies = async (type: MovieType, page: number) => {
  const data = await get(`/${type}/${page !== 1 ? `page/${page}/` : ''}`);

  const $ = load(data as any);
  const $moviesSection = $('#dle-content');
  const movies = findMovieItems($moviesSection, $);

  return movies;
};

export const searchMovies = async (query: string) => {
  const data = await get(
    `/index.php?do=search&subaction=search&story=${query}`,
  );

  const $ = load(data as any);
  const $moviesSection = $('#dle-content');
  const movies = findMovieItems($moviesSection, $);

  return movies;
};

export const getMovie = async (link: string) => {
  const data = await get(link, true);

  const $ = load(data as any);
  const $moviePage = $('#dle-content');

  const infoSections = $moviePage
    .find('.film-info .clearfix')
    .map((_, element) => {
      const $element = $(element);
      return {
        title: $element.find('.fi-label').text() || 'IMDb',
        value: $element.find('.fi-desc').text().replace(/ , /g, ', '),
      };
    })
    .toArray()
    .slice(0, -1);

  const seasons = $('.seasons.clearfix')
    .find('li')
    .map((_, season) => {
      return {
        name: $(season).text(),
        link: $(season).find('a').attr('href') || link,
      };
    })
    .toArray();

  const movie = {
    title: $moviePage.find('.solototle').text(),
    subTitle: $moviePage.find('.origintitle').text(),
    image: $moviePage.find('.film-poster a').attr('href'),
    quality: $moviePage.find('.film-poster .full-quality').text(),
    description: $moviePage
      .find('.full-text.clearfix')
      .text()
      .replace(/\n/g, '')
      .replace(/\t/g, '')
      .trim(),
    infoSections,
    id: $moviePage.find('.playlists-ajax').attr('data-news_id'),
    url: $moviePage.find('iframe#pre').attr('src'),
    seasons,
  };

  return movie;
};

export const getSources = async (id: string) => {
  const data = await get(
    `/engine/ajax/playlists.php?news_id=${id}&xfield=playlist&time=${
      Date.now() / 1000
    }`,
    false,
    true,
    true,
    {
      Referer: 'https://uakino.me',
      'X-Requested-With': 'XMLHttpRequest',
      'User-Agent':
        'Mozilla/5.0 (Windows NT 10.0; rv:126.0) Gecko/20100101 Firefox/126.0',
    },
  );

  const $ = load(data.response as any);
  const sources = $('.playlists-lists')
    .find('li')
    .map((_, source) => {
      return {
        id: $(source).attr('data-id'),
        name: $(source).text(),
      };
    })
    .toArray();
  const videos = $('.playlists-videos')
    .find('li')
    .map((_, source) => {
      return {
        name: $(source).text(),
        id: $(source).attr('data-id'),
        link: $(source).attr('data-file'),
        voice: $(source).attr('data-voice'),
      };
    })
    .toArray();
  return {
    sources,
    videos,
  };
};

export const getVideo = async (link: string) => {
  const data = await get(
    link[0] === '/' ? link.replace('//', 'https://') : link,
    true,
    false,
    false,
  );

  const url = data.match(/file:"(.*?)"/);
  const poster = data.match(/poster:"(.*?)"/);
  const subtitle = data.match(/subtitle:"(.*?)"/);
  const video = {
    url: url ? url[1] : '',
    poster: poster ? poster[1] : '',
    subtitle: subtitle ? subtitle[1] : '',
  };

  return video;
};

export const saveToHistory = (movie: Movie) => {
  const history = JSON.parse(localStorage.getItem(HISTORY) || '[]');
  let newHistory = [];
  if (history.find((item: Movie) => item.link === movie.link)) {
    newHistory = history.map((item: Movie) =>
      item.link === movie.link
        ? { ...movie, time: new Date().getTime() }
        : item,
    );
  } else {
    newHistory = [{ ...movie, time: new Date().getTime() }, ...history];
  }
  localStorage.setItem(HISTORY, JSON.stringify(newHistory));
};

export type WatchedEpisode = {
  link: string;
  viewedPercentage: number;
};

export const saveWatchedEpisodeLink = ({
  link,
  viewedPercentage,
}: WatchedEpisode) => {
  let watchHistory: WatchedEpisode[] = JSON.parse(
    localStorage.getItem(WATCH_HISTORY) || '[]',
  );

  if (watchHistory.find(item => item.link === link)) {
    watchHistory = watchHistory.map(item =>
      item.link === link ? { ...item, viewedPercentage } : item,
    );
  } else {
    watchHistory = [{ link, viewedPercentage }, ...watchHistory];
  }

  localStorage.setItem(WATCH_HISTORY, JSON.stringify(watchHistory));
};
