import React, { useCallback, useEffect, useState } from 'react';
import {
  FocusContext,
  setFocus,
  useFocusable,
} from '@noriginmedia/norigin-spatial-navigation';
import { ActionsWrapper } from './styled';
import Loader from 'components/Loader/Loader';
import { ContentRowTitle } from 'components/ContentRow/styled';
import Asset from 'components/Asset/Asset';
import { getMovies, Movie, MovieType } from 'api/ua-kino';
import Button from 'components/Button/Button';
import { ContentWrapper, ScrollingRows } from 'styled';
import { useNavigate } from 'react-router-dom';

interface MoviesPageProps {
  type: MovieType;
  title: string;
}

const MoviesPage = ({ type, title }: MoviesPageProps) => {
  const { ref, focusKey } = useFocusable();
  const [movies, setMovies] = useState<Movie[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const fetchData = async () => {
    setIsLoading(true);
    const data = await getMovies(type, currentPage);
    setMovies(currentPage === 1 ? data : [...movies, ...data]);
    setIsLoading(false);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [type]);

  useEffect(() => {
    if (currentPage) {
      fetchData();
    } else {
      setCurrentPage(1);
    }
  }, [currentPage]);

  useEffect(() => {
    if (movies.length) {
      const index = Math.ceil(movies.length / 40 - 1) * 40;
      movies[index]?.link && setFocus(movies[index].link);
    }
  }, [movies]);

  const onAssetFocus = useCallback(
    ({ y }: { y: number }) => {
      ref.current.scrollTo({
        top: y,
        behavior: 'smooth',
      });
    },
    [ref],
  );

  const loadMore = () => {
    setCurrentPage(page => page + 1);
  };

  const openMovie = (link: string) => {
    navigate(`/movie/?link=${link}`);
  };

  return (
    <FocusContext.Provider value={focusKey}>
      <Loader isLoading={isLoading}>
        <ContentWrapper>
          <ContentRowTitle>{title}</ContentRowTitle>
          <ScrollingRows ref={ref}>
            {movies.map(movie => (
              <Asset
                key={movie.link}
                focusKey={movie.link}
                title={movie.name}
                image={movie.image}
                onFocus={onAssetFocus}
                onEnterPress={() => openMovie(movie.link)}
              />
            ))}
            {!!movies.length && (
              <ActionsWrapper>
                <Button onEnterPress={loadMore}>Завантажити ще</Button>
              </ActionsWrapper>
            )}
          </ScrollingRows>
        </ContentWrapper>
      </Loader>
    </FocusContext.Provider>
  );
};

export default MoviesPage;
